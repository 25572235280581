import type { NextPage } from 'next';
import { useState, useEffect } from 'react';
import CloseButton from '../../components/closeButton/closeButton';
import TabPanel from '../../components/tabPanel/tabPanel';
import { useGlobalState } from '../../context/globalState';
import { Modal, Box, Typography, FormControlLabel, Divider, Checkbox, Button, Tabs, Tab } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useStyles } from '../../styles/sharedStyles';

interface Props {
  open: boolean;
  handleCloseModal: () => void;
  handleDownloadData: (table: string, columns:{ [key: string]: boolean }) => void;
  reportExport: boolean;
  selectedTab?: string;
  exportOrgSize: number;
  exportPeopleSize: number;
}

const ExportModal: NextPage<Props> = ({
  open, handleCloseModal, handleDownloadData, reportExport, selectedTab, exportOrgSize, exportPeopleSize
}) => {
  const { peopleColumns, companyColumns, numberFormatter } = useGlobalState();
  const [table, setTable] = useState<string>('people');
  const [availablePeopleExportColumns, setAvailablePeopleExportColumns] = useState<GridColDef[]>([]);
  const [availableCompanyExportColumns, setAvailableCompanyExportColumns] = useState<GridColDef[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<{ [key: string]: { [key: string]: boolean } }>({});
  const [allColumnsSelected, setAllColumnsSelected] = useState<boolean>(true);
  const [companyColumnsSortOrder] = useState<{ [key: string]: number }>({
    'COMPANY.company_name_org': 300, 'COMPANY.linkedin_industry_org': 290, 'COMPANY.domain_org': 285, 'COMPANY.revenue_range_org': 280,
    'COMPANY.employee_count_range_org': 270, 'COMPANY.phone_org': 260, 'COMPANY.street_org': 250, 'COMPANY.city_org': 240,
    'COMPANY.state_name_org': 230, 'COMPANY.postcode_org': 220, 'COMPANY.country_name_org': 210, 'COMPANY.industry_sic_description_org': 200,
    'COMPANY.industry_sic_code_org': 195, 'COMPANY.industry_naics_description_org': 190, 'COMPANY.industry_naics_code_org': 180
  });
  const [peopleColumnsSortOrder] = useState<{ [key: string]: number }>({
    'COMPANY.company_name_org': 300, 'TAM.name_per': 295, 'COMPANY.revenue_range_org': 290, 'COMPANY.employee_count_range_org': 280,
    'COMPANY.phone_org': 270, 'COMPANY.street_org': 260, 'COMPANY.city_org': 250, 'COMPANY.state_name_org': 240, 'COMPANY.postcode_org': 230,
    'COMPANY.country_name_org': 220, 'COMPANY.industry_sic_description_org': 210, 'COMPANY.industry_sic_code_org': 205,
    'COMPANY.industry_naics_description_org': 200, 'COMPANY.industry_naics_code_org': 195, 'COMPANY.linkedin_industry_org': 190,
    'TAM.direct_phone_per': 120, 'TAM.job_title_per': 150, 'TAM.job_level_per': 140, 'TAM.email_address_per': 110, 'TAM.email_status_per': 100,
    'TAM.cellphone_per': 130, 'TAM.city_per': 80, 'TAM.state_name_per': 70, 'TAM.country_name_per': 50,
  });
  const [columnsToPrependOrg] = useState<string[]>([
    'COMPANY.phone_org', 'COMPANY.street_org', 'COMPANY.city_org', 'COMPANY.state_org', 'COMPANY.postcode_org', 'COMPANY.country_name_org'
  ]);
  const [requiredCompanyColumns] = useState<string[]>(['COMPANY.company_name_org']);
  const [requiredPeopleColumns] = useState<string[]>(['COMPANY.company_name_org', 'TAM.name_per']);
  const classes = useStyles();

  useEffect(() => {
    if (peopleColumns.length && companyColumns.length) {
      setSelectedColumns({
        ['people']: [
          ...peopleColumns,
          ...companyColumns.filter((column: GridColDef) => (
            !peopleColumns.find((item: GridColDef) => column.field.split('.')[1] === item.field.split('.')[1]) && column
          ))
        ].reduce((prevValue: { [key: string]: boolean }, currentValue: GridColDef) => ({ ...prevValue, [currentValue.field as string]: true }), {}),
        ['company']: [
          ...companyColumns,
        ].reduce((prevValue: { [key: string]: boolean }, currentValue: GridColDef) => ({ ...prevValue, [currentValue.field as string]: true }), {}),
      });
      setAvailablePeopleExportColumns([
        ...peopleColumns,
        ...companyColumns.filter((column: GridColDef) => (
          !peopleColumns.find((item: GridColDef) => column.field.split('.')[1] === item.field.split('.')[1]) && column
        ))
      ].sort((aColumn: GridColDef, bColumn: GridColDef) => {
        const bValue = peopleColumnsSortOrder[bColumn.field] || 20;
        const aValue = peopleColumnsSortOrder[aColumn.field] || 20;
        return bValue - aValue;
      }));
      setAvailableCompanyExportColumns([...companyColumns.sort((aColumn: GridColDef, bColumn: GridColDef) => {
        const bValue = companyColumnsSortOrder[bColumn.field] || 20;
        const aValue = companyColumnsSortOrder[aColumn.field] || 20;
        return bValue - aValue;
      })]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peopleColumns, companyColumns]);

  useEffect(() => {
    if (selectedColumns && Object.keys(selectedColumns).length) {
      if (table === 'people') {
        const checkedColumnsLength = Object.keys(selectedColumns[table]).filter((value: string) => selectedColumns[table][value]).length;
        setAllColumnsSelected(checkedColumnsLength === availablePeopleExportColumns.length);
      } else {
        const checkedColumnsLength = Object.keys(selectedColumns[table]).filter((value: string) => selectedColumns[table][value]).length;
        setAllColumnsSelected(checkedColumnsLength === availableCompanyExportColumns.length);
      }
    }
  }, [selectedColumns, table, availablePeopleExportColumns, availableCompanyExportColumns]);

  useEffect(() => {
    if (selectedTab) {
      setTable(selectedTab);
    }
  }, [selectedTab]);

  const handleColumnToggle = (columnName: string, columnSelected: boolean) => {
    setSelectedColumns({
      ...selectedColumns,
      [table]: {
        ...selectedColumns[table],
        [columnName]: columnSelected,
      }
    });
  };

  const handleExportData = () => {
    handleDownloadData(table, selectedColumns[table]);
    setTable(selectedTab ? selectedTab : 'people');
  };

  const toggleValue = (table: string, column: string, value: boolean): boolean => {
    if (table === 'people') {
      return requiredPeopleColumns.includes(column) ? true : value;
    } else {
      return requiredCompanyColumns.includes(column) ? true : value;
    }
  };

  const toggleAllSelection = (table: string, value: boolean) => {
    setSelectedColumns({
      ...selectedColumns,
      [table]: Object.keys(selectedColumns[table])
        .reduce((prevValue: { [key: string]: boolean }, currentValue: string) => ({
          ...prevValue,
          [currentValue]: toggleValue(table, currentValue, value)
        }), {})
    });
  };

  const disableExportButton = (): boolean => {
    if (table === 'people') {
      return exportPeopleSize === 0 ? true : false;
    } else {
      return exportOrgSize === 0 ? true : false;
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => handleCloseModal()}
    >
      <Box className={classes.exportModal}>
        <Box className='u-relative u-p-32'>
          <Box>
            <Typography className='fs-title-large u-fw-500'>Export dataset</Typography>
            <Typography className='fs-title-regular u-fw-700 u-pt-16'>
              {numberFormatter(table === 'people' ? exportPeopleSize : exportOrgSize)} Total records selected
            </Typography>
            <CloseButton handleCloseModal={() => handleCloseModal()} />
          </Box>
          {!reportExport ? (
            <>
              <Box className='u-pb-32 u-pt-24'>
                <Tabs
                  value={table}
                  onChange={(event: React.SyntheticEvent, value: string) => setTable(value)}
                  TabIndicatorProps={{
                    style: {
                      display: 'none',
                    },
                  }}
                >
                  <Tab
                    label={
                      <Box component='span' className='u-flex u-flex-align-center fs-body-regular u-text-transform-none'>
                        Organizations & People
                      </Box>
                    }
                    value='people'
                    classes={{
                      root: 'month-tab-left export-tab',
                      selected: 'month-tab-selected'
                    }}
                  />
                  <Tab
                    label={
                      <Box component='span' className='u-flex u-flex-align-center fs-body-regular u-text-transform-none'>
                        Organizations only
                      </Box>
                    }
                    value='company'
                    classes={{
                      root: 'month-tab-right export-tab',
                      selected: 'month-tab-selected'
                    }}
                  />
                </Tabs>
              </Box>
              <Divider className='divider' />
            </>
          ) : null}
          <Box className='u-pt-32 u-pb-16 u-px-12'>
            <FormControlLabel
              className='checkbox-button'
              control={
                <Checkbox
                  checked={allColumnsSelected}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAllColumnsSelected(event.target.checked);
                    toggleAllSelection(table, true);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                  classes={{
                    root: 'checkbox-root'
                  }}
                />
              }
              label='Select All'
              name='all'
            />
            <Button
              className='details-button u-ml-16 u-w-100-px'
              onClick={() => {
                setAllColumnsSelected(false);
                toggleAllSelection(table, false);
              }}
            >
              Clear All
            </Button>
          </Box>
          <TabPanel value={table} index='people'>
            <Box className='u-pb-20 u-pt-12 u-px-20 u-flex u-flex-justify-start u-flex-wrap u-w-100 u-br-4-px export-checkbox-wrapper'>
              {availablePeopleExportColumns.length ? availablePeopleExportColumns.map((column: GridColDef) => (
                <FormControlLabel
                  key={column.field}
                  className='checkbox-button'
                  control={
                    <Checkbox
                      checked={selectedColumns[table][column.field as string]}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleColumnToggle(event.target.name, event.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      classes={{
                        root: 'checkbox-root'
                      }}
                      disabled={requiredPeopleColumns.includes(column.field)}
                    />
                  }
                  label={
                    <Box component='span' sx={{ color: '#2F3B4A' }}>
                      {columnsToPrependOrg.includes(column.field || '') &&
                        !column.headerName?.startsWith('Org.')
                        ? `Org. ${column.headerName}`
                        : column.headerName}
                      {requiredPeopleColumns.includes(column.field) ? '*' : ''}
                    </Box>
                  }
                  name={column.field}
                />
              )) : null}
            </Box>
          </TabPanel>
          <TabPanel value={table} index='company'>
            <Box className='u-pb-20 u-pt-12 u-px-20 u-flex u-flex-justify-start u-flex-wrap u-w-100 u-br-4-px export-checkbox-wrapper'>
              {availableCompanyExportColumns.length ? availableCompanyExportColumns.map((column: GridColDef) => (
                <FormControlLabel
                  key={column.field}
                  className='checkbox-button'
                  control={
                    <Checkbox
                      checked={selectedColumns[table][column.field as string]}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleColumnToggle(event.target.name, event.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      classes={{
                        root: 'checkbox-root'
                      }}
                      disabled={requiredCompanyColumns.includes(column.field)}
                    />
                  }
                  label={
                    <Box component='span' sx={{ color: '#2F3B4A' }}>
                      {column.headerName}
                      {requiredCompanyColumns.includes(column.field) ? '*' : ''}
                    </Box>
                  }
                  name={column.field}
                />
              )) : null}
            </Box>
          </TabPanel>
          <Box className='u-pt-12'>
            <Typography className='fs-body-regular u-pb-12'>* Required</Typography>
            <Box className='u-flex u-flex-justify-end'>
              <Button
                variant='outlined'
                className='outlined-button u-w-100-px u-mr-12 u-text-transform-none'
                onClick={() => handleCloseModal()}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                className='contained-button u-w-100-px u-text-transform-none'
                onClick={() => handleExportData()}
                disabled={disableExportButton()}
              >
                Export
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ExportModal;